import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import logo from './logo.svg';
import './App.css';

function App() {
  const { keycloak, initialized } = useKeycloak();

  if (!initialized) {
    return <div>Loading Keycloak...</div>;
  }

  const login = () => {
    keycloak.login({ idpHint: 'google', prompt: 'login'  });
  };

  const logout = () => {
    keycloak.logout({ redirectUri: window.location.origin });
  };

  if (keycloak && keycloak.authenticated) {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h2>Welcome, {keycloak.tokenParsed?.preferred_username}!</h2>
          <p>You are now authenticated with Keycloak (via Google)!</p>
          <button onClick={logout}>Logout</button>
        </header>
      </div>
    );
  } else {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h2>Please login to continue</h2>
          <button onClick={login}>Login with Keycloak / Google</button>
        </header>
      </div>
    );
  }
}

export default App;