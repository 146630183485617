// src/keycloak.js
import Keycloak from 'keycloak-js';

// Replace these values with your Keycloak server details
const keycloak = new Keycloak({
  url: 'https://keycloak.lb.alphavisionoption.ai',  // Your Keycloak auth server URL
  realm: 'trade',                   // Your realm name
  clientId: 'my-react-client',        // Your public client ID
});

export default keycloak;